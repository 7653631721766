<template>
  <div class="container has-text-centered">
    <h1 class="title is-2">
      {{ title }}
    </h1>
    <div class="columns">
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Prices",
  components: {},
  data() {
    return {
      title: "ລາຄາຂົນສົ່ງ",
    };
  },
  mounted() {},
  methods: {},
};
</script>
